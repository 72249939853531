import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Seo from "../components/seo";
import TwoColumnLayout from '../components/twoColumnLayout';
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageGrid1x1 = styled(GatsbyImage)`
 width: 100%;
 grid-column-start: 1;
 grid-column-end: 3;
 ${MEDIA.PHONE`
   grid-column-start: 1;
   grid-column-end: 1;
   `};
`

const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(50% - 2rem, 0fr));
grid-gap: 2rem;
margin: 2rem;
${MEDIA.PHONE`
  grid-template-columns: repeat(auto-fill, minmax(100%, 0fr));
  `};
`

const TextContainer = styled.div`
position: fixed;
top: 0;
bottom: 0;
max-width: 533px;
z-index: 1000;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
padding-right: 2rem;
padding-top: 2rem;
&::-webkit-scrollbar {
    display: none;
}
${MEDIA.TABLET`
    width: 100%;
    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
  `};
${MEDIA.PHONE`
  width: 100%;
  position: static;
  align-items: left;
  padding: 2rem;
  padding-bottom: 0;
  z-index: 10;
  `};
`

const NGA = () => {

  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "NGA"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  const images = [];
  for (let i = 0; i < data.allFile.edges.length; i++) {
    images[i] = getImage(data.allFile.edges[i].node)
  }

  return(
        <TwoColumnLayout
        Column1Size="66"
        Column1Content={
          <GridContainer>
            {images.map((image, index) => (
                <ImageGrid1x1 image={image} alt={data.allFile.edges[index].node.name} key={index}/>
            ))}
          </GridContainer>

        }
        Column2Size="33"
        Column2Content={
          <TextContainer>
          <Seo title="NGA-Launchpads" />
          <Header siteTitle="Jake Mu" />
          <h2>
            <i>NGA Launchpads</i>
            <br /><br />
            {'The National Gallery of Australia approached us with a brief to create launchpads targeted for their audience to explore the 150,000+ artworks within their collection. After looking through the data, we created two launchpad prototypes. One launchpad uses the artworks place of origin to visualise a map showing the range of the museums collection as well as forming interesting insights into the places in which their collection originates. Another launchpad uses artwork tags to create data visualisation overviews each displaying the locations, time periods and object types to form interesting links such as “nuclear” artworks during cold war time or “nature” artworks being mostly sketches.'}
            <br /><br />
            <b>client:</b><br />
            {'National Galleries of Australia'}
            <br /><br />
            <b>role:</b><br />
            {'Experience Designer @ Grumpy Sailor'}
            <br />
            <i>{'– Design Lead'}</i><br />
            <i>{'– Concepting'}</i><br />
            <i>{'– Art Direction'}</i><br />
          </h2>
        </TextContainer>

        }
        />
  )
}

export default NGA;
